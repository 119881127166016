<template>
  <div class="container">
    <Top></Top>
    <Fix></Fix>
    <!-- <Else></Else> -->
    <div class="index-box">
        <div class="index-wrap-box">
              <div class="trade">
                  <router-link  tag="div" :to="{path:'/trade'}">商标服务</router-link>
                    <div class="div1">
                        <div v-for="(v,k,index) in myarr" class="div1-box">
                            <p @click="moveto(v.goodsName)"> {{v.goodsName}}<span>|</span></p>
                        </div>
                    </div>
              </div>
              <div class="patent">
                  <!-- <div>专利服务</div> -->
                  <router-link tag="div" :to="{path:'/patent'}">专利服务</router-link>
                  <div class="div4">
                      <div v-for="(v,k,index) in copyarr" :key="index" class="div2-box">
                          <p @click="moveto1(v.goodsName)">{{v.goodsName}}<span>|</span></p>
                      </div>
                  </div>
              </div>
              <div class="copyright">
                  <router-link tag="div" :to="{path:'/copyright'}">版权服务</router-link>
                  <!-- <div>版权服务</div> -->
                  <div class="div3">
                      <div v-for="(v,k,index) in copyright" :key="index" class="div3-box">
                          <p @click="moveto2(v.goodsName)">{{v.goodsName}}<span>|</span></p>
                      </div>
                  </div>
              </div>
              <!-- <div class="property">
                  <div @click="brandBuy">知产交易</div>
              </div> -->
              <!-- <div class="index-fix-tool">
                  <div>知产工具</div>
                  <div class="div2">
                      <div @click="toregister">商标评估<span>|</span></div>
                      <div @click="creatname">商标取名<span>|</span></div>
                      <div @click="todesign">Logo设计<span>|</span></div>
                      <div @click="assets">知产管理<span>|</span></div>
                      <div @click="assets2">工商核名<span>|</span></div>
                      <div @click="topatentanalyse">专利申请评估</div>
                  </div>
              </div> -->
              <div class="abouts">
                  <div>关于我们</div>
                  <div class="div5">
                      <router-link tag="p" to="/about">公司介绍</router-link><span>|</span>
                      <router-link tag="p" to="/link">联系我们</router-link>
                      <!-- <span>|</span>
                      <router-link tag="p" to="/news">新闻中心</router-link> -->
                  </div>
              </div>
          </div>
          <div class="index-box-title">
            <div>
              <div class="index-title">商标成功率·AI评估</div>
              <div class="index-dec">8000万商标数据 | 智能推荐类别 | 下证率精确到小类 | 在线注册申请</div>
            </div>        
          </div>
          <div class="index-search">
            <div class="index-search-box-div1">
              <div :class="changeStatus">
                  <span class="span1" @click="changeImage('indexSearch')">商标智能检索</span>
                  <span class="span2" @click="changeImage('imageCrop')">图形检索</span>
              </div>
            </div>
            <div class="index-search-box-div2" v-show="imageShow">
                <input type="text" placeholder="输入您想检索的商品名称、申请号、申请人姓名" v-model="word">
                <span @click="moveTo">搜索</span>
            </div>
            <div class="index-search-box-div3" v-show="!imageShow">
              <el-upload
              class="upload-demo"
              drag
              accept="image/*"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="selectChange"
              ref="fileUpload"
              action=""
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将图片拖拽到这里上传，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">
                <div>
                  <span @click="watchExample">示例图片</span>
                  <el-upload
                    class="upload-demo2"
                    accept="image/*"
                    ref="fileUpload"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="selectChange"
                     action=""
                    >
                    <span style="color: #f7f7f7;">图片上传</span>
                  </el-upload>
                </div>
              </div>
            </el-upload>
            <cropper
                  v-if="showCropper"
                  :dialog-visible="showCropper"
                  :cropper-img="cropperImg"
                  @update-cropper="updateCropper"
                  @colse-dialog="closeDialog"
                  @upload-img="uploadImg"
                />
            </div>
          </div>
          <div class="index-top-tool">
            <div class="top-tool-bigdiv">
              <div class="big-div1">
                <div>
                  热门工具
                </div>
              </div>
              <div class="big-div2">
                <div v-for="(v,k) in tool">
                    <div class="bigzhen1" @mouseenter="toolshow(k)">
                      <img :src="v.img1" alt="">
                      <div>{{v.title}}</div>
                    </div>
                    <div class="bigzhen2" @mouseleave="toolleave()" v-show="k==currenttool" @click="tohot(k)">
                      <img :src="v.img2" alt="">
                      <div>{{v.title}}</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    <div class="index-tool">
        <div class="Box">
            <div class="content">
              <div class="Box_con clearfix">
                <!-- <span class="btnl btn" id="btnl"></span>
                <span class="btnr btn" id="btnr"></span> -->
                <div class="conbox" id="BoxUl">
                  <ul>
                     <!-- <li class="cur" @click="agencyactive"><img src="/static/images/5.png" alt=""/>
                     <div>福利还剩：<span>{{agencyprice}}元</span></div></li> -->
                    <!-- <li class="cur" ><img :src="require('/static/images/1.png')" alt=""/></li>
                    <li class="cur" ><img :src="require('/static/images/2.png')" alt=""/></li>
                    <li class="cur" ><img :src="require('/static/images/3.png')" alt=""/></li>
                   <li class="cur"><img :src="require('/static/images/4.png')" alt=""/></li> -->
                   <li class="cur" v-for="(item,index) in bannerList" :key="index" @click="toAnoth(item.activityUrl)"><img style="border-radius:5px;" :src="item.activityPicture" alt=""/></li>
                  </ul>
                </div>
                <!-- <p class="BoxSwitch" id="BoxSwitch">
                  <span class="cur"></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </p> -->
              </div>
            </div>
          </div>
          <div class="index-tool-title"><div>高效知识产权专业工具</div> </div>
          <div class="index-tool-title-english">Efficient intellectual property professional tools</div>
          <div class="index-tool-list">
            <div :class="active">
              <div class="div-div1" @mouseenter="change('success')">商标成功率评估</div>
              <div class="div-div2" @mouseenter="change('name')">AI商标取名</div>
              <div class="div-div3" @mouseenter="change('design')">AI-LOGO设计</div>
              <div class="div-div4" @mouseenter="change('manage')">知产管理</div>
            </div>
          </div>
    </div>
    <div class="index-tool-detail">
        <div class="index-success" v-show="changeshow1">
        <div>
          <img :src="require('/static/images/success.png')" alt="">
        </div>
        <div>
          <div>商标成功率评估</div>
          <div>
            ·AI分析商标注册成功指数<br/>
            ·AI推荐高成功率商标名称<br/>
            ·智能预警提醒 避免近似商标<br/>
            ·商标星级评估 结果直观展现<br/>
            ·一键快速提交商标注册
          </div>
          <div @click="toregister">立即评估</div>
        </div>
      </div>
      <div class="index-success" v-show="changeshow2">
        <div>
          <img :src="require('/static/images/name.png')" alt="">
        </div>
        <div>
          <div>AI商标取名</div>
          <div>
            ·海量词库批量推荐优选商标<br/>
            ·根据您的喜好获取商标名称<br/>
            ·智能监控商标名称变化<br/>
            ·一键快速提交商标注册
          </div>
          <div @click="direct">立即取名</div>
        </div>
      </div>
      <div class="index-success" v-show="changeshow3">
        <div>
          <img :src="require('/static/images/design.png')" alt="">
        </div>
        <div>
          <div>AI-LOGO设计</div>
          <div>
            ·AI快速生成原创商标图形<br/>
            ·AI推荐精准匹配商标类别<br/>
            ·简化设计流程轻松完成<br/>
            ·一键快速提交商标注册
          </div>
          <div @click="todesign">立即设计</div>
        </div>
      </div>
      <div class="index-success" v-show="changeshow4">
        <div>
          <img :src="require('/static/images/manage.png')" alt="">
        </div>
        <div>
          <div>知产管理</div>
          <div>
            ·监控管理知识产权<br/>
            ·提醒知识产权信息变化<br/>
            ·分析统计知识产权数据<br/>
            ·政策精准匹配 快速扶持企业
          </div>
          <div @click="assets()">立即查看</div>
        </div>
      </div>
    </div>
    <div class="index-risk">
      <div class="index-risk-title"><div>智能服务 专业保证</div> </div>
      <div class="index-risk-title-english">Intelligent service professional guarantee</div>
      <div class="index-risk-list">
        <div class="index-risk-list-left">
          <div class="left1"  @mouseenter="changeimg(1)">商<br>标<br>服<br>务</div>
          <div class="left2"  @mouseenter="changeimg(2)">专<br>利<br>服<br>务</div>
          <div class="left3" @mouseenter="changeimg(3)">版<br>权<br>服<br>务</div>
          <img :src="require('/static/images/tradeimg.png')" alt="" v-show="imgshow1">
          <img :src="require('/static/images/patentimg.png')" alt="" v-show="imgshow2">
          <img :src="require('/static/images/copyrightimg.png')" alt="" v-show="imgshow3">
        </div>
        <div class="index-risk-list-right" v-show="imgshow1">
          <div class="right1">
              <div v-for="(v,k) in  [...good].splice(0,4)" @mouseenter="showhover(k)" :key="k">
                <div>
                    <div class="right1-title">{{v.goodsName}}</div>
                      <div class="right1-desc">{{v.goodsDescribe}}</div>
                      <div class="right1-promision" v-html="v.promision">
                      </div>
                      <div class="right1-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
                <div v-show="k==current2" @mouseleave="leave2()" @click="tradedetail(v.goodsName)">
                    <div class="right1-title">{{v.goodsName}}</div>
                      <div class="right1-desc">{{v.goodsDescribe}}</div>
                      <div class="right1-promision" v-html="v.promision">
                      </div>
                      <div class="right1-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
            </div>
          </div>
          <div class="right2">
              <div v-for="(v,k) in [...good].splice(4,4)" :key="k" @mouseenter="showhover2(k)">
                <div>
                      <div class="right2-title">{{v.goodsName}}</div>
                      <div class="right2-desc">{{v.goodsDescribe}}</div>
                      <img v-show="k == 0" :src="require('/static/images/index1.png')" alt="">
                      <img v-show="k == 1" :src="require('/static/images/index2.png')" alt="">
                      <img v-show="k == 2" :src="require('/static/images/index3.png')" alt="">
                      <img v-show="k == 3" :src="require('/static/images/index4.png')" alt="">
                      <div class="right2-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
                <div v-show="k==current3" @mouseleave="leave3()" @click="tradedetail(v.goodsName)">
                      <div class="right2-title">{{v.goodsName}}</div>
                      <div class="right2-desc">{{v.goodsDescribe}}</div>
                      <img v-show="k == 0" :src="require('/static/images/index1.png')" alt="">
                      <img v-show="k == 1" :src="require('/static/images/index2.png')" alt="">
                      <img v-show="k == 2" :src="require('/static/images/index3.png')" alt="">
                      <img v-show="k == 3" :src="require('/static/images/index4.png')" alt="">
                      <div class="right2-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
            </div>
          </div>
        </div>
        <div class="index-risk-list-right" v-show="imgshow2">
          <div class="right1">
              <div v-for="(v,k) in  [...good2].splice(0,4)" @mouseenter="showhover(k)" :key="k">
                <div>
                    <div class="right1-title">{{v.goodsName}}</div>
                      <div class="right1-desc">{{v.goodsDescribe}}</div>
                      <div class="right1-promision" v-html="v.promision">
                      </div>
                      <div class="right1-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
                <div v-show="k==current2" @mouseleave="leave2()" @click="todetail(v.goodsName)">
                    <div class="right1-title">{{v.goodsName}}</div>
                      <div class="right1-desc">{{v.goodsDescribe}}</div>
                      <div class="right1-promision" v-html="v.promision">
                      </div>
                      <div class="right1-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
            </div>
          </div>
          <div class="right3">
              <div v-for="(v,k) in [...good2].splice(4,2)" :key="k" @mouseenter="showhover2(k)">
                <div>
                      <div class="right3-title">{{v.goodsName}}</div>
                      <div class="right3-desc">{{v.goodsDescribe}}</div>
                      <img v-show="k == 0" :src="require('/static/images/index5.png')" alt="">
                      <img v-show="k == 1" :src="require('/static/images/index6.png')" alt="">
                      <div class="right3-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
                <div v-show="k==current3" @mouseleave="leave3()" @click="todetail(v.goodsName)">
                      <div class="right3-title">{{v.goodsName}}</div>
                      <div class="right3-desc">{{v.goodsDescribe}}</div>
                      <img v-show="k == 0" :src="require('/static/images/index5.png')" alt="">
                      <img v-show="k == 1" :src="require('/static/images/index6.png')" alt="">
                      <div class="right3-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
            </div>
          </div>
        </div>
        <div class="index-risk-list-right" v-show="imgshow3">
          <div class="right1">
              <div v-for="(v,k) in  [...good3].splice(0,4)" @mouseenter="showhover(k)" :key="k">
                <div>
                    <div class="right1-title">{{v.goodsName}}</div>
                      <div class="right1-desc">{{v.goodsDescribe}}</div>
                      <div class="right1-promision" v-html="v.promision">
                      </div>
                      <div class="right1-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
                <div v-show="k==current2" @mouseleave="leave2()" @click="todetail(v.goodsName)">
                    <div class="right1-title">{{v.goodsName}}</div>
                      <div class="right1-desc">{{v.goodsDescribe}}</div>
                      <div class="right1-promision" v-html="v.promision">
                      </div>
                      <div class="right1-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
            </div>
          </div>
          <div class="right3">
              <div v-for="(v,k) in [...good3].splice(4,2)" :key="k" @mouseenter="showhover2(k)">
                <div>
                      <div class="right3-title">{{v.goodsName}}</div>
                      <div class="right3-desc">{{v.goodsDescribe}}</div>
                      <img v-show="k == 0" :src="require('/static/images/index5.png')" alt="">
                      <img v-show="k == 1" :src="require('/static/images/index6.png')" alt="">
                      <div class="right3-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
                <div v-show="k==current3" @mouseleave="leave3()" @click="todetail(v.goodsName)">
                      <div class="right3-title">{{v.goodsName}}</div>
                      <div class="right3-desc">{{v.goodsDescribe}}</div>
                      <img v-show="k == 0" :src="require('/static/images/index5.png')" alt="">
                      <img v-show="k == 1" :src="require('/static/images/index6.png')" alt="">
                      <div class="right3-price"><span>¥</span><span>{{v.price}}</span>/件</div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-advantages">
      <div class="index-advantages-title"><div>我们的服务优势</div> </div>
      <div class="index-advantages-title-english">Our service advantage</div>
      <div class="index-advantages-detail">
        <div>
          <img :src="require('/static/images/advantang1.png')" alt="">
          <p>海量数据</p>
          <p>8000万商标大数据库，快速准确提取数据信息，全面分析，精准评估</p>
        </div>
        <div>
          <img :src="require('/static/images/advantang2.png')"  alt="">
          <p>知产管理</p>
          <p>专属知产智能管理系统，VIP定制式商标监控预警、智能提醒，实现自主管理</p>
        </div>
        <div>
          <img :src="require('/static/images/advantang3.png')"  alt="">
          <p>极速响应</p>
          <p>智能机器人极速响应，专业知识产权顾问一对一服务，效率加倍</p>
        </div>
        <div>
          <img :src="require('/static/images/advantang4.png')"  alt="">
          <p>专业团队</p>
          <p>深耕知产行业12年，携手AI博士研发知产服务系统，安全可靠</p>
        </div>
        <div>
          <img :src="require('/static/images/advantang5.png')"  alt="">
          <p>在线申请</p>
          <p>知产申请流程在线化、智能化，申请材料一键递交审查，拒绝繁琐</p>
        </div>
      </div>
    </div>
    <div class="index-goverment">
      <div class="index-goverment-title"><div>政府项目</div> </div>
      <div class="index-goverment-title-english">Government projects</div>
      <div class="index-goverment-chosecity">
          <div class="chosecity1">
            <span v-for="(v,k) in city" :key="k" :class="{colorChange:v.province==dynamic1}" @click="changeCity(v.province)">{{v.province}}</span>
          </div>
          <div class="chosecity2">
            <span v-for="(v,k) in area" :key="k" :class="{colorChange2:v.country==dynamic2}" @click="changeArea(v.country)">{{v.country}}</span>
          </div>
          <div class="chosecity3">
              <el-carousel :interval="4000" type="card" height="400px">
                <el-carousel-item v-for="(v,k) in lunbo" :key="k">
                    <div class="medium" @mouseenter="enter(k)">
                      <img :src="require('/static/images/govermentshu.png')" alt="">
                      <div>
                        <p>{{v.projectName}}</p>
                        <p>主管单位：{{v.department}}</p>
                      </div>
                    </div>

                    <div class="medium2" v-show="seen&&k==current" @mouseleave="leave()" @click="togovermentDetail(v.projectCode)">
                      <img :src="require('/static/images/govermentshu.png')" alt="">
                      <p>{{v.projectName}}</p>
                      <p>主管单位：{{v.department}}</p>

                      <div>
                        <div>扶持政策:{{v.policy}}</div>
                        <div><p>主要条件:</p><p>{{v.mainCondition}}</p></div>
                        <div><p>申报日期:</p><p>{{v.endTime}}</p></div>
                      </div>
                    </div>

                </el-carousel-item>
              </el-carousel>
          </div>
          <div class="chosecity4" @click="watchmore">更多 <i class="el-icon-d-arrow-right"></i></div>
      </div>
    </div>
    <!-- <div class="index-new">
      <div class="index-new-div1"><div>行业热点动态</div></div>
      <div class="index-new-english">Industry trends</div>
      <div class="index-new-div2">
          <div class="index-new-div2-top">
              <div class="news-top1">
                <el-carousel :interval="5000">
                  <el-carousel-item v-for="(v,k) in newsTop" :key="k" >
                    <div @click="next(v.id)">
                      <img class="news-top1-img" :src="GLOBAL.base_url+v.url_img" alt="">
                      <div class="news-top1-div">
                          <div>{{v.new_title}}</div>
                          <div>{{v.abstract}}</div>
                      </div>
                    </div>
                  </el-carousel-item>
              </el-carousel>
              </div>
              <div class="news-top2" v-for="(v,k) in newsDetail" v-if='k== 3' @click="next(v.id)">
                  <img :src="GLOBAL.base_url+v.url_img" alt="">
                  <div>
                      <div>{{v.new_title}}</div>
                      <div>{{v.abstract}}</div>
                  </div>
              </div>
          </div>
          <div class="index-new-div2-detail">
              <div class="new-detail-list" v-for="(v,k) in [...newsDetail].splice(0,3)" @click="next(v.id)">
                <div class="list1">
                  <div>热点</div>
                  <div>{{v.new_title}}</div>
                </div>
                <div class="list2">{{v.abstract}}</div>
              </div>
          </div>
      </div>
      <div class="index-news-more" @click="tonews">
        <div>
          <span>更多资讯</span>
          <img :src="require('/static/images/goverment5.png')" alt="">
        </div>
      </div>
    </div> -->
    <div class="index-cover" v-show="indexCovershow">
      <div class="index-cover-tan">
        <img :src="require('/static/images/cha.png')" alt="" @click="colseCom">
        <div class="index-cover-div">
            <div class="index-cover-div1">将为您服务的顾问</div>
            <div class="index-cover-div2">
                <div>
                  <img :src="myConsultant.headImgUrl?myConsultant.headImgUrl:require('/static/images/moren.jpeg')" alt="">
                </div>
                <div class="index-cover-div2-name">
                    <div>专属顾问-{{ myConsultant.empName }}</div>
                    <div>
                      <el-rate
                      v-model="myConsultant.adviseLevel"
                        disabled
                        score-template="{value}">
                      </el-rate>
                    </div>
                    <div class="index-cover-div2-opeat" @click="changeCons">更换顾问</div>
                </div>
                <div class="index-cover-div2-link">
                  <div>
                    <img :src="require('/static/images/consultantwei.png')" alt="">
                    <span>{{myConsultant.wxAccount}}</span>
                  </div>
                  <div>
                    <img :src="require('/static/images/consultanttel.png')" alt="">
                    <span>{{myConsultant.empMobile}}</span>
                  </div>
                  <div class="index-cover-div2-opeat op2" @click="colseCom">确认</div>
                </div>
            </div>
        </div>
      </div>
  </div>
    <Foot></Foot>
    <!-- <div class="index-example" v-show="exampleSHOW"> -->
      <el-dialog class="index-example" :visible.sync="exampleSHOW">
        <div class="index-example-div">
            <span class="index-example-span">截取图形要素示例图</span>
            <div class="index-example-middle">
                <span>请截取图形要素、非图形要素影响检索成功率</span>
                <div>
                  <div v-for="(v,k) in exampleimg">
                    <img :src="v.url" alt="">
                    <div>{{v.text}}</div>
                  </div>
                </div>
                
            </div>
            <div class="index-example-buttom">
              <span @click="watchExample2">取消</span>
              <el-upload
                class="upload-demo2"
                accept="image/*"
                ref="fileUpload"
                :show-file-list="false"
                :auto-upload="false"
                    :on-change="selectChange"
                 action=""
                >
                <span style="color: #f7f7f7;">图片上传</span>
              </el-upload>
            </div>
          </div>
        <!-- </div> -->
      </el-dialog>
    </div>
  </div>

</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
import {LbMove} from '@/Lunbo.js';
// import Else from '../ui/else.vue';
import Cropper from "./crop";
export default {
  name: 'index',
  data(){
    return {
      dialogVisible2:true,
      bannerimg:[
        {url:require('/static/images/banner7.png')},
        {url:require('/static/images/banner6.png')},
        // {url:'/static/images/banner5.png'},
        {url:require('/static/images/banner1.png')},
        // {url:'/static/images/banner2.png'},
        {url:require('/static/images/banner3.png')},
        {url:require('/static/images/banner4.png')},

      ],
      myarr:[],
      id:'',
      copyarr:[],
      word:'',
      tool:[{
              img1: require('/static/images/hot1.png'),
              img2: require('/static/images/hot1c.png'),
              title:'商标成功率评估'
          },
          {
              img1: require('/static/images/hot2.png'),
              img2: require('/static/images/hot2c.png'),
              title:'专利申请评估'
          },
          {
              img1: require('/static/images/hot3.png'),
              img2: require('/static/images/hot3c.png'),
              title:'知产管理'
          },
          {
              img1: require('/static/images/hot5.png'),
              img2: require('/static/images/hot5c.png'),
              title:'商标分类'
          }],
          see:false,
          currenttool:Number,
          imgArr:[],
          active:'success',
          changeshow1:true,
          changeshow2:false,
          changeshow3:false,
          changeshow4:false,
          imgshow1:true,
          imgshow2:false,
          imgshow3:false,
          type:1,
          // 商标所有商品
          good:[],
          // 版权所有商品
          good2:[],
          // 专利所有商品
          good3:[],
          city:[],
          area:[],
          lunbo:[],
          dynamic1:'',
          dynamic2:'全部',
          seen:false,
          current:0,
          copyarr:[],
          copyright:[],
          current2:Number,
          current3:Number,
          newsTop:[],
          newsDetail:[],
          bannerList:[],
          // 判断用户是否第一次登录
          indexCovershow:false,
          myConsultant: [],
          consultantArr:[],
          // 图片裁剪
          showCropper: false,
          cropperImg: "",
          imageShow:true,
          exampleimg:[
        {url:require('/static/images/crop1.png'),text:'截取示列一'},
        {url:require('/static/images/crop2.png'),text:'截取示列二'},
        {url:require('/static/images/crop3.png'),text:'截取示列三'},
      ],
      exampleSHOW:false,
      // 搜索和检索切换
      changeStatus:'indexSearch',

    }
  },
  components: {
    Top,
    Foot,
    // Else,
    Fix,
    Cropper
  },
  watch:{
    'active'(value){
      if(value == 'success'){
        this.changeshow1 = true;
        this.changeshow2 = false;
        this.changeshow3 = false;
        this.changeshow4 = false;
      }else if(value == 'name'){
        this.changeshow1 = false;
        this.changeshow2 = true;
        this.changeshow3 = false;
        this.changeshow4 = false;
      }else if(value == 'design'){
        this.changeshow1 = false;
        this.changeshow2 = false;
        this.changeshow3 = true;
        this.changeshow4 = false;
      }else if(value == 'manage'){
        this.changeshow1 = false;
        this.changeshow2 = false;
        this.changeshow3 = false;
        this.changeshow4 = true;
      }
    }
  },
  methods: {
    tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:3000
          })
      },
      toolshow(k){
        this.currenttool = k;
      },
      toolleave(){
        this.currenttool = null;
      },
      tohot(val){
        if(val == 0){
          this.toregister();
        }else if(val == 1){
          // this.topatentanalyse();
          this.tip('error','此业务正在开通中，敬请期待')
        }else if(val == 2){
          this.assets();
        }else{
          this.tolisttype();
        }
      },
      tostudy(){
        let routeData = this.$router.resolve({ path: '/study'});
        window.open(routeData.href, 'blank');
      },
      toagencyimg(){
        let routeData = this.$router.resolve({ path: '/agencyimg'});
        window.open(routeData.href, 'blank');
      },
      tomember(){
        let routeData = this.$router.resolve({ path: '/memberCenter'});
        window.open(routeData.href, 'blank');
      },
      agencyactive(){
        if(this.$cookie.getCookie('u_id')){
          let routeData = this.$router.resolve({ path: '/agencyactive'});
          window.open(routeData.href, 'blank');
        }else{
          this.$router.push({
              path:'/login',
              query:{
              good:0
              }
          })
          }
      },
      toactive(k){
        if(k==0){
          this.agencyactive();
        }else if(k==1){
          this.tostudy();
        }else if(k==3){
          this.tomember();
        }else if(k == 4){
          this.toagencyimg();
        }
      },
      toprogrem(){
        this.$router.push('/load');
      },
      tolisttype(){
        this.$router.push('/listtype');
      },
      topatentanalyse(){
        if(this.$cookie.getCookie('u_id')){
          this.$router.push('/patentAnalyse');
        }else{
          this.$router.push({
              path:'/login',
              query:{
              good:0
              }
          })
          }
      },
      getNav(){
            this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
              goodsLevel:'1',
              goodsType:"1"
            }).then(res=>{
              if(res.data.code == "200"){
                // console.log(res)
                this.myarr = res.data.data;
              }
            })
        },
        getList1(){
          this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
            goodsLevel:'1',
              goodsType:"2"
          }).then( res => {
            // console.log(res)
            this.copyarr = res.data.data
          })
        },
      getList2(){
        this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
          goodsLevel:'1',
          goodsType:"3"
        }).then( res => {
          // console.log(res)
          this.copyright = res.data.data
        })
      },
      togovermentDetail(val){
        this.$router.push({
              path:'/govermentDetail',
              query:{
                id:val
              }
          })
      },
      moveto(val){
              this.$router.push({
              path:'/tradeDetail',
                  query:{
                      parentName:val,
                      secondName:0
                  }
              });
          },
      moveto1(val){
          this.$router.push({
                path:'/patentDetail',
                query:{
                      parentName:val,
                      secondName:0
                  }
          })
      },
       //版权跳转
        moveto2(val){
            this.$router.push({
                      path:'/copyrightDetail',
                      query:{
                      parentName:val,
                      secondName:0
                  }
            })

        },
            change(val){
            this.active = val
          },
          toregister(){
          if(this.$cookie.getCookie('u_id')){
            this.$router.push({
                    path:'/recome',
                    query:{
                      goodsName:'AI商标注册'
                    }
                })
          }else{
            this.$router.push({
              path:'/login',
              query:{
                good:0
              }
            })
          }
        },
         creatname(){
              if(this.$cookie.getCookie('u_id')){
                this.$router.push('/picksize')
                }else{
                this.$router.push({
                    path:'/login',
                    query:{
                    good:0
                    }
                })
                }
          },
      moveTo(){
        if(this.$cookie.getCookie('u_id')){
                this.$router.push({
                  path:'/search',
                  query:{
                    word:this.word
                  }
                  })
              }else{
                this.$router.push({
                    path:'/login',
                    query:{
                    good:0
                    }
                })
              }
      },
      direct(){
        // if(this.$cookie.getCookie('u_id')){
        //   this.$router.push('/picksize')
        // }else{
        //   this.$router.push({
        //     path:'/login',
        //     query:{
        //       good:0
        //     }
        //   })
        // }
        this.tip('error','此业务正在开通中，敬请期待')
      },
      todesign(){
        // if(this.$cookie.getCookie('u_id')){
        //   this.$router.push('/designbrand')
        // }else{
        //   this.$router.push({
        //     path:'/login',
        //     query:{
        //       good:0
        //     }
        //   })
        // }
        this.tip('error','此业务正在开通中，敬请期待')
      },
      assets(){
        if(this.$cookie.getCookie('u_id')){
          this.$router.push('/intellectualProperty')
        }else {
          this.$router.push({
                path:'/login',
                query:{
                good:0
              }
          })
        }
      },
      assets2(){
        if(this.$cookie.getCookie('u_id')){
          this.$router.push('/verification')
        }else {
          this.$router.push({
                path:'/login',
                query:{
                good:0
              }
          })
        }
      },
      getrisk(){
        // 商标所有产品
      this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
        goodsLevel:'2',
        userCode:this.$cookie.getCookie('u_id'),
        goodsType:'1'
      }).then(res=>{
                    // console.log(res)
          if(res.data.code == "200"){
            const agent = window.localStorage.getItem('UserAgent')
              this.good = res.data.data;
              for(var i =0;i<this.good.length;i++){
                  var _this=this;
                  if(agent == 'HT'){
                    _this.$set(this.good[i], 'price', this.good[i].proxyPrice+100);
                  }else{
                    _this.$set(this.good[i], 'price', this.good[i].proxyPrice);
                  }
                    

              }
          }
          // console.log(this.good)
      })
      // 专利所有产品
      this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
        goodsLevel:'2',
        userCode:this.$cookie.getCookie('u_id'),
        goodsType:'2'
      }).then(res=>{
                    // console.log(res)
          if(res.data.code == "200"){
            const agent = window.localStorage.getItem('UserAgent')
              this.good2 = res.data.data;
              for(var i =0;i<this.good2.length;i++){
                  var _this=this;
                  if(agent == 'HT'){
                    _this.$set(this.good2[i], 'price', this.good2[i].proxyPrice+100);
                  }else{
                    _this.$set(this.good2[i], 'price', this.good2[i].proxyPrice);
                  }
                    

              }
          }
      })
      // 版权所有商品
      this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
        goodsLevel:'2',
        userCode:this.$cookie.getCookie('u_id'),
        goodsType:'3'
      }).then(res=>{
                    // console.log(res)
          if(res.data.code == "200"){
            const agent = window.localStorage.getItem('UserAgent')
              this.good3 = res.data.data;
              for(var i =0;i<this.good3.length;i++){
                  var _this=this;
                  if(agent == 'HT'){
                    _this.$set(this.good3[i], 'price', this.good3[i].proxyPrice+100);
                  }else{
                    _this.$set(this.good3[i], 'price', this.good3[i].proxyPrice);
                  }
                    

              }
          }
      })
       
      },
      changeimg(val){
            this.type = val
            if(this.type == 1){
              this.imgshow1 = true;
              this.imgshow2 = false;
              this.imgshow3 = false;
            }else if(this.type == 2){
              this.imgshow1 = false;
              this.imgshow2 = true;
              this.imgshow3 = false;
            }else{
              this.imgshow1 = false;
              this.imgshow2 = false;
              this.imgshow3 = true;
            }
          },
           enter(index){
            this.seen = true;
            this.current = index;
          },
            leave(){
              this.seen = false;
              this.current = null;
          },
          showhover(k){
            this.current2 = k;
          },
          leave2(){
            this.current2= null;
          },
          showhover2(k){
            this.current3 = k;
          },
          leave3(){
            this.current3= null;
          },
          watchmore(){
            this.$router.push('/govermentList')
          },
          tradedetail(val){
            this.$router.push({
              path:'/tradeDetail',
                  query:{
                      parentName:0,
                      secondName:val
                  }
              });
          },
          todetail(val){
            if(this.type == 2){
            this.$router.push({
                path:'/patentDetail',
                query:{
                      parentName:0,
                      secondName:val
                  }
              })
          }else{
            this.$router.push({
                path:'/copyrightDetail',
                query:{
                      parentName:0,
                      secondName:val
                  }
              })
          }
        },
        // getgoverment(){
        //   this.$http.get(this.GLOBAL.base_url+'/api/new/governmentNews').then(res=>{
        //     // console.log(res)
        //             if(res.data.code == "1"){
        //               this.newsTop = res.data.data.stick;
        //               this.newsDetail = res.data.data.guid;
        //             }
        //         })
        // },
        getgoverment2(){
          this.$http.post(this.GLOBAL.new_url+'/goods/service/GovernmentGoods/queryGovernment',{
              projectName:this.govermentword,
              pageNum:1,
              pageSize:1000
          }).then(res=>{
            // console.log(res)
                    if(res.data.code == "200"){
                       let arr = res.data.data.list;
                       let dataArr = [];
                        arr.map(mapItem => {
                          if (dataArr.length == 0) {
                              dataArr.push({ province: mapItem.province, country: mapItem.country,List: [mapItem] })
                          } else {
                            let res = dataArr.some(item=> {//判断相同日期，有就添加到当前项
                              if (item.country == mapItem.country) {
                                item.List.push(mapItem);
                                return true;
                              }
                            })
                            if (!res) {//如果没找相同日期添加一个新对象
                              dataArr.push({ province: mapItem.province,country: mapItem.country, List: [mapItem] })
                            }
                          }
                        })
                        // console.log(dataArr)
                        let dataArr2 = [];
                        dataArr.map(mapItem => {
                          if (dataArr2.length == 0) {
                              dataArr2.push({ province: mapItem.province, country: mapItem.country,List: [mapItem] })
                          } else {
                            let res = dataArr2.some(item=> {//判断相同日期，有就添加到当前项
                              if (item.province == mapItem.province) {
                                item.List.push(mapItem)
                                return true;
                              }
                            })
                            if (!res) {//如果没找相同日期添加一个新对象
                              dataArr2.push({ province: mapItem.province,country: mapItem.country, List: [mapItem] })
                            }
                          }
                        })
                      // console.log(dataArr2)
                      this.city = dataArr2;
                      this.area = this.city[0].List;
                      this.dynamic1 = this.city[0].province;
                      this.dynamic2 = this.city[0].country;
                      this.lunbo = this.city[0].List[0].List;
                    }
                })
        },
        changeCity(val){
          this.dynamic1 = val
          for(var i =0;i<this.city.length;i++){
          if(this.dynamic1 == this.city[i].province){
            this.dynamic2 = this.city[i].country;
            this.area = this.city[i].List;
            this.lunbo = this.city[i].List[0].List;
          }
        }
        },
        changeArea(val){
          this.dynamic2 = val
          for(var i = 0;i<this.city.length;i++){
            for(var j = 0;j<this.city[i].List.length;j++){
              if(val == this.city[i].List[j].country){
                this.lunbo = this.city[i].List[j].List;
              }
            }
          }
        },
      next(val){
        this.$router.push({
          path:'/news/newsdetail',
          query:{
            id:val
          }})
      },
      tonews(){
        this.$router.push('/news')
      },
    brandBuy() {
      this.$router.push('/brandBuy')
    },
    getBanner(){
      this.$http.post(this.GLOBAL.new_url+'/operation/service/activity/queryActivity').then(res=>{
                    // console.log(res)
          if(res.data.code == "200"){
              this.bannerList = res.data.data;
              // this.$nextTick(()=>{
              //     LbMove('BoxUl','btnl','btnr','BoxSwitch',true,'left',true,305,1000,3000,this.bannerList.length-1);
              //   })
          }
          // console.log(this.good)
      })
    },
    toAnoth(val){
      // console.log(val)
      if(val!==""){
        window.open(val, "_blank");
      }
      
    },
    getConsulat(){
      this.consultantArr = [];
      this.myConsultant = [];
      this.consultant = this.$cookie.getCookie('consultant');
      this.$http.post(this.GLOBAL.new_url + '/user/service/getAdviserList', {
        userCode: this.$cookie.getCookie('u_id'),
        allAdvise: "1"
      }, {
        headers: {
          AuthToken: this.$cookie.getCookie('token'),
          'X-Request-Origin': 'xcx'
        }
      }).then(res => {
        // console.log(res)
        if (res.data.code == "200") {
          this.consultantArr = res.data.data;
          for (var i = 0; i < this.consultantArr.length; i++) {
            this.consultantArr[i].adviseLevel = Number(this.consultantArr[i].adviseLevel)
            if (this.consultant == this.consultantArr[i].empCode) {
              this.myConsultant = this.consultantArr[i]
            }
          }
          this.indexCovershow = true;
          this.$cookie.setCookie("from", 1, 7);
          this.$http.post(this.GLOBAL.new_url+'/user/service/updateLoginNum',{
            userCode: this.$cookie.getCookie('u_id')
            }).then(res=>{
              if(res.data.code == "200"){
                console.log(123)
              }
            })
        }
      })
    },
    colseCom(){
      this.indexCovershow = false;
    },
    changeCons(){
      this.$router.push('/personal/consultant')
    },
    changeImage(val){
      this.changeStatus = val
      if(val == 'indexSearch'){
        this.imageShow = true
        
      }else{
        this.imageShow = false
        
      }
      
    },
    selectChange(file){
      this.exampleSHOW = false;
      this.$nextTick(async () => {
        this.cropperImg = URL.createObjectURL(file.raw);
        this.showCropper = true;
      });
  },
    updateCropper(){
      this.$refs.fileUpload.$children[0].$el.click()
    },
    closeDialog(){
      this.showCropper = false;
    },
    uploadImg(data){
      // console.log(data)
      this.showCropper = false;
      localStorage.setItem('cropImg', data);
      this.$router.push('/cropImage')
    },
    watchExample(){
      this.exampleSHOW = true;
    },
    watchExample2(){
      this.exampleSHOW = false;
    }
  },
   mounted() {
     this.getBanner();
     this.getNav();
     this.getrisk();
     this.getList1();
     this.getList2();
    //  this.getgoverment();
     this.getgoverment2();
     if(this.$cookie.getCookie('u_id')&&this.$cookie.getCookie('from')== "0"){
          this.getConsulat()
        }
    }
}
</script>

<style scoped>
  .index-box{
    width: 100%;
    height: 654px;
    background: url('../../../static/images/indexTOP.png') no-repeat;
    background-size: cover;
    background-color: #005EFB;
    position: relative;
  }
  .index-wrap-box{
    width: 1200px;
    height: 24px;
    left: 50%;
    top:20px;
    transform: translate(-50%,0);
    position: absolute;
    z-index: 10;
  }
  .index-wrap-box>div{
    float: left;
    width: 68px;
    font-size: 17px;
    color: #ffffff;
    margin-right: 60px;
  }
  .trade,.copyright,.patent,.property,.abouts,.index-fix-tool{
        position: relative;
        cursor: pointer;
    }
    .div1,.div2,.div3,.div4{
        position:absolute;
        background-color: rgba(51, 51, 51, 0.8);
        height: 0;
        transition: all 1s ease;
        opacity: 0;
        z-index: 10;
        overflow: hidden;
        padding-left: 20px;
    }
    .div5{
        width: 200px;
        position:absolute;
        background-color: rgba(51, 51, 51, 0.9);
        height: 0;
        transition: all 1s ease;
        opacity: 0;
        z-index: 3;
        overflow: hidden;
        left: -75px;
        margin-top: 6px;
        padding-top:13px;
        text-align: center;
    }
    .abouts:hover .div5{
        height: 32px;
        opacity: 1;
    }
    .abouts:hover{
      color: #EB5E00;
    }
    .div5>p{
        float: left;
        font-size: 12px;
        color: #ffffff;
        height: 30px;
        margin: 0 22px;
        vertical-align: middle;
    }
    .div5>span{
      float: left;
      font-size: 14px;
      color: #ffffff;
      vertical-align: middle;
    }
    .div1,.div4{
        width: 562px;
        margin-top: 6px;
        left: -60px;
    }
    .div1>div,.div4>div{
        width: 150px;
        float: left;
        height: 16px;
        font-size: 12px;
        color: #ffffff;
        line-height: normal;
        margin-right: 30px;
        margin-top: 15px;
        text-align: center;
    }
    .div1>div>span,.div4>div>span{
      float: right;
    }
    .div1>div>p>span,.div4>div>p>span{
      float: right;
    }
    .div2{
        line-height: normal;
        width: 580px;
        margin-top: 6px;
        left: -200px;
    }
    .div2>div{
        float: left;
        width: 95px;
        height: 20px;
         color: white;
         text-align: center;
         font-size: 12px;
         margin-top: 13px;
    }
    .div2>div>span{
        float: right;
    }
    .index-fix-tool:hover .div2{
        height: 40px;
        opacity: 1;
    }
    .index-fix-tool:hover{
      color: #EB5E00;
    }
    .copyright:hover .div3{
        height: 76px;
        opacity: 1;
    }
    .copyright:hover{
      color: #EB5E00;
    }
    .div3>div>p>span{
      float: right;
    }
    .div3{
        width:410px;
        margin-top: 6px;
        left: -140px;
    }
    .div3>div{
      width: 120px;
        float: left;
        height: 16px;
        font-size: 12px;
        color: #ffffff;
        line-height: normal;
        margin-top: 15px;
        text-align: center;
    }
    .trade:hover{
      color: #EB5E00;
    }
    .trade:hover .div1{
        height: 76px;
        opacity: 1;
    }
    .patent:hover{
      color: #EB5E00;
    }
    .patent:hover .div4{
        height: 110px;
        opacity: 1;
    }
    .index-box-title{
      width: 1200px;
      position: absolute;
      height: 100px;
      top:100px;
      left: 50%;
      transform: translate(-50%,0);
  }
  .index-title{
    width: 338px;
    height: 56px;
    font-size: 40px;
    margin: 0 auto 8px;
    text-align: center;
    color: white;
  }
 .index-dec{
  width: 985px;
  height: 56px;
  font-size: 32px;
    margin: 0 auto;
    text-align: center;
    color: white;
 }
.index-search{
    width: 850px;
    height: 100px;
    left: 50%;
    top:300px;
    transform: translate(-50%,-50%);
    position: absolute;
    z-index: 10;
  }
  .index-search-box-div1{
    font-size: 18px;
    font-weight: 500;
    color: rgb(203, 208, 211);
    margin-right: 4px;
    margin-bottom: 15px;
  }
  .index-search-box-div1>div>span{
    margin-right: 42px;
    cursor: pointer;
  }
  .indexSearch .span1,.imageCrop .span2{
    color: #FFFFFF;
  }
  .index-search-box-div2{
    margin-top: 50px;
  }
  .index-search-box-div2>input{
    width: 567px;
    height: 57px;
    background: #FFFFFF;
    border-radius: 6px;
    opacity: 0.95;
    outline: none;
    border: none;
    font-size: 16px;
    padding-left: 28px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    vertical-align: middle;
  }
  .index-search-box-div2>span{
    display: inline-block;
    width: 154px;
    height: 57px;
    background: #EB5E00;
    border-radius: 6px;
    opacity: 0.9;
    vertical-align: middle;
    font-size: 20px;
    text-align: center;
    line-height: 57px;
    color: #FFFFFF;
    cursor: pointer;
    margin-left: -3px;
  }
  .index-search-box-div3{
    width: 818px;
    height: 212px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 16px 16px 0;
  }
  /deep/ .el-upload-dragger{
    width: 818px;
    height: 146px;
  }
  .el-upload__tip{
    margin-top: 16px;
  }
  .el-upload__tip>div{
    width: 245px;
    margin: 0 auto;
  }
  .el-upload__tip>div>span{
    display: inline-block;
    width: 110px;
    height: 34px;
    border-radius: 6px;
    border:1px solid #EB5E00;
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    color: #EB5E00;
    cursor: pointer;
  }
  .upload-demo2{
    width: 110px;
    height: 34px;
    border-radius: 6px;
    float: right;
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    background: #EB5E00;
  }

  .index-top-tool{
     width: 100%;
     height: 80px;
     position: absolute;
     z-index: 5;
     bottom:0;
     background: rgba(41, 43, 57, 0.2);
  }
  .top-tool-bigdiv{
    width: 1165px;
    height: 80px;
    margin: 0 auto;
    /* border:1px solid red; */
  }
.top-tool-bigdiv>div{
  float: left;
}
.big-div1{
  width: 71px;
  height: 64px;
  margin: 8px 92px 0 0;
  background: url('../../../static/images/hottool.png') no-repeat;
}
.big-div1>div{
  width: 42px;
  height: 56px;
  font-size: 20px;
  color: white;
  margin: 4px 0 0 12px;
}
.big-div2{
  height: 64px;
  margin-top: 6px;
  /* border:1px solid red; */
}
.big-div2>div{
  position: relative;
  width: 112px;
  text-align: center;
  font-size: 16px;
  color: white;
  float: left;
}
.big-div2>div>div{
  width: 112px;
  text-align: center;
}
.big-div2>div:nth-of-type(1){
  margin-right: 152px;
}
.big-div2>div:nth-of-type(2){
  margin-right: 160px;
}
.big-div2>div:nth-of-type(3){
  margin-right: 160px;
}
.bigzhen1{
  cursor: pointer;
}
.bigzhen2{
  position: absolute;
  color: #EB5E00;
  top:0;
  z-index: 10;
  cursor: pointer;
}
.index-tool{
  width: 100%;
  height: 378px;
  background-color: #f7f7f7;
  padding-top: 60px;
}
.Box {position: relative;}
.Box .content {width: 1200px;margin: 0 auto 40px;}
.Box .Box_con {position: relative;}
.Box .Box_con .btnl {position: absolute;}
.Box .Box_con .btn {display: block;width: 21px;height: 37px;position: absolute;top: 80px;cursor: pointer;}
.Box .Box_con .btnl {background: url('../../../static/images/jtl02.png') no-repeat center;left: 0px; z-index: 10;}
.Box .Box_con .btnr {background: url('../../../static/images/jtr02.png') no-repeat center;right: 0px; z-index: 10;}
.Box .Box_con .conbox {position: relative;overflow: hidden;}
.Box .Box_con .conbox ul {position: relative;list-style: none;}
.Box .Box_con .conbox ul li {float: left;width: 285px;height: 200px;margin-left: 20px;overflow: hidden;cursor: pointer;}
.Box .Box_con .conbox ul li:first-child {margin-left: 0;}
.Box .Box_con .conbox ul li img {display: block;width: 285px;height: 200px;transition: all 0.5s;}
.Box .Box_con .conbox ul li div{width: 285px;height: 50px;line-height: 50px; position: absolute;top:123px;z-index: 2;font-size: 20px;text-align: center;color: #fdd468;}
.Box .Box_con .conbox ul li div span{font-size: 32px; font-weight: 600;}
.Box .Box_con .conbox ul li:hover img {transform: scale(1.1);}
.Box .BoxSwitch {margin-top: 30px;text-align: center;}
.Box .BoxSwitch span {display: inline-block;*display: inline;*zoom: 1;vertical-align: middle;width: 30px;height: 3px;background: #ccc;margin: 0 5px;cursor: pointer;}
.Box .BoxSwitch span.cur {background: red;}
.index-tool-title{
    width: 596px;
    height: 45px;
    background: url('../../../static/images/title-img.png') no-repeat;
    margin: 0 auto;
    background-position:center;
  }
  .index-tool-title>div{
    width: 320px;
    height: 45px;
    font-size: 32px;
    font-weight: 100;
    margin: 0 auto;
    color: #333333;
  }
  .index-tool-title-english{
    width: 330px;
    height: 20px;
    font-size: 14px;
    text-align: center;
    margin: 0 auto 31px;
    color: #999999;
  }
  .index-tool-list{
    width: 900px;
    height: 40px;
    margin: 0 auto 28px;
  }
  .index-tool-list>div{
    height: 40px;
  }
  .index-tool-list>div>div{
    width: 128px;
    text-align: center;
    float: left;
    height: 37px;
    font-size: 16px;
    color: #333333;
    margin-right: 128px;
    cursor: pointer;
  }
.index-tool-list>div>div:last-child{
  margin-right: 0;
}
.success .div-div1,.name .div-div2,.design .div-div3,.manage .div-div4{
    color: #EB5E00;
    border-bottom: 3px solid #EB5E00;
  }
.index-tool-detail{
  width:100%;
  height: 500px;
  padding-top: 28px;
  background: url('../../../static/images/tool.png') no-repeat;
  background-size: cover;
}
.index-success{
  width: 975px;
  height: 480px;
  margin: 0 auto;
}
.index-success>div{
  float: left;
}
.index-success>div>img{
  width: 444px;
  height: 410px;
}
.index-success>div:nth-of-type(2){
  width: 231px;
  margin-left: 200px;
}
.index-success>div:nth-of-type(2)>div:nth-of-type(1){
  margin: 52px 0 25px;
  font-size: 22px;
  color: #333333;
}
.index-success>div:nth-of-type(2)>div:nth-of-type(2){
  margin-bottom: 28px;
  height: 160px;
  font-size: 18px;
  line-height: 1.78;
  color: #999999;
}
.index-success>div:nth-of-type(2)>div:nth-of-type(3){
  width: 118px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 16px;
  color: #EB5E00;
  border: solid 2px #EB5E00;
  cursor: pointer;
}
.index-risk{
  width: 100%;
  height: 648px;
  padding-top: 37px;
  background: url('../../../static/images/risk.png') no-repeat;
  background-size: cover;
}
.index-risk-title{
  width: 543px;
  height: 45px;
  background: url('../../../static/images/title-img2.png') no-repeat;
  margin: 0 auto;
  background-position:center;
}
.index-risk-title>div{
  width: 267px;
  height: 45px;
  font-size: 32px;
  font-weight: 100;
  margin: 0 auto;
  color: #333333;
}
.index-risk-title-english{
  width: 300px;
  margin: 0 auto 51px;
  font-size: 14px;
  text-align: center;
  color: #999999;
}
.index-risk-list{
  width: 1210px;
  height: 450px;
  margin: 0 auto;
  /* border:1px solid red; */
}
.index-risk-list>div{
  float: left;
}
.index-risk-list-left{
  position: relative;
  width: 268px;
  height: 450px;
}
.index-risk-list-left>img{
  width: 288px;
  height: 470px;
  position: absolute;
  top:-10px;
  left: -10px;
}
.index-risk-list-left>div{
  width: 35px;
  height: 75px;
  position: absolute;
  z-index: 10;
  color: white;
  font-size: 16px;
  line-height: 1.13;
  /* border:1px solid red; */
  text-align: center;
  cursor: pointer;
}
.left1{
  top:45px;
}
.left2{
  top:145px;
}
.left3{
  top:245px;
}
.index-risk-list-right{
  width: 940px;
  height: 450px;
  margin-top: -2px;
  box-shadow: 0 2px 8px 0 rgba(255, 107, 0, 0.19);
}
.right1{
  height: 277px;
}
.right1>div{
  float: left;
  width: 234px;
  height: 276px;
  border-right:solid 1px #dadadd;
  border-bottom:solid 1px #dadadd;
  cursor: pointer;
  position: relative;
}
.right1>div>div:nth-of-type(1){
  position: absolute;
  z-index: 2;
  width: 234px;
  height: 276px;
  border-right:solid 1px #dadadd;
  border-bottom:solid 1px #dadadd;
  background: #ffffff;
}
.right1>div>div:nth-of-type(2){
  position: absolute;
  z-index: 10;
  box-shadow: 0 8px 12px 0 rgba(255, 107, 0, 0.19);
  width: 234px;
  height: 276px;
  border-right:solid 1px #dadadd;
  border-bottom:solid 1px #dadadd;
  background: #ffffff;
}
.right1-title{
  margin: 29px 0 6px 21px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}
.right1-desc{
  width: 197px;
  height: 38px;
  font-size: 14px;
  margin: 0 0 30px 21px;
  color: #999999;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.right1-promision{
  width: 187px;
  height: 75px;
  overflow: hidden;
  font-size: 14px;
  color: #333333;
  margin: 0 0 8px 21px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.right1-price{
  margin-left: 65px;
  font-size: 14px;
  color: #333333;
}
.right1-price>span:nth-of-type(1){
  font-size: 20px;
  color: #EB5E00;
}
.right1-price>span:nth-of-type(2){
  font-size: 30px;
  color: #EB5E00;
}
.right2{
  height: 172px;
}
.right2>div{
  float: left;
  width: 234px;
  border-right:solid 1px #dadadd;
  border-bottom:solid 1px #dadadd;
  cursor: pointer;
  height: 172px;
  position: relative;
}
.right2>div>div:nth-of-type(1){
  position: absolute;
  z-index: 2;
  width: 234px;
  height: 172px;
  border-right:solid 1px #dadadd;
  border-bottom:solid 1px #dadadd;
  background: #ffffff;
}
.right2>div>div:nth-of-type(2){
  position: absolute;
  z-index: 10;
  box-shadow: 0 8px 12px 0 rgba(255, 107, 0, 0.19);
  width: 234px;
  height: 172px;
  border-right:solid 1px #dadadd;
  border-bottom:solid 1px #dadadd;
  background: #ffffff;
}
.right2-title{
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0 6px 21px;
  color: #333333;
}
.right2-desc{
  width: 194px;
  height: 40px;
  margin: 0 0 20px 21px;
  font-size: 14px;
  color: #999999;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.right2>div>div>img{
  position: absolute;
  z-index: -10;
  right: 4px;
  bottom: 0;
}
.right2-price{
  margin-left: 21px;
  font-size: 12px;
  color: #333333;
}
.right2-price>span:nth-of-type(1){
  font-size: 14px;
  color: #EB5E00;
}
.right2-price>span:nth-of-type(2){
  font-size: 26px;
  color: #EB5E00;
}
.right3{
  height: 172px;
}
.right3>div{
  float: left;
  width: 469px;
  border-right:solid 1px #dadadd;
  border-bottom:solid 1px #dadadd;
  cursor: pointer;
  height: 172px;
  position: relative;
}
.right3>div>div:nth-of-type(1){
  position: absolute;
  z-index: 2;
  width: 469px;
  height: 172px;
  border-right:solid 1px #dadadd;
  border-bottom:solid 1px #dadadd;
  background: #ffffff;
}
.right3>div>div:nth-of-type(2){
  position: absolute;
  z-index: 10;
  box-shadow: 5px 8px 12px 0 rgba(255, 107, 0, 0.19);
  width: 469px;
  height: 172px;
  border-right:solid 1px #dadadd;
  border-bottom:solid 1px #dadadd;
  background: #ffffff;
}
.right3-title{
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0 6px 21px;
  color: #333333;
}
.right3-desc{
  width: 264px;
  height: 40px;
  margin: 0 0 20px 21px;
  font-size: 14px;
  color: #999999;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.right3>div>div>img{
  position: absolute;
  z-index: -10;
  right: 0;
  bottom: 0;
}
.right3-price{
  margin-left: 175px;
  font-size: 12px;
  color: #333333;
}
.right3-price>span:nth-of-type(1){
  font-size: 14px;
  color: #EB5E00;
}
.right3-price>span:nth-of-type(2){
  font-size: 26px;
  color: #EB5E00;
}
.index-advantages{
  width: 100%;
  height: 440px;
  padding-top: 55px;
}
.index-advantages-title{
  width: 500px;
  height: 45px;
  background: url('../../../static/images/title-img3.png') no-repeat;
  margin: 0 auto;
  background-position:center;
}
.index-advantages-title>div{
  width: 224px;
  height: 45px;
  font-size: 32px;
  font-weight: 100;
  margin: 0 auto;
  color: #333333;
}
.index-advantages-title-english{
  width: 285px;
  margin: 0 auto 50px;
  font-size: 14px;
  text-align: center;
  color: #999999;
}
.index-advantages-detail{
  width: 1195px;
  height: 235px;
  margin: 0 auto;
}
.index-advantages-detail>div{
  float: left;
  width: 231px;
  text-align: center;
  margin-right: 10px;
}
.index-advantages-detail>div:last-child{
  margin-right: 0;
}
.index-advantages-detail>div>p:nth-of-type(1){
  margin:6px 0 6px;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
}
.index-advantages-detail>div>p:nth-of-type(2){
  font-size: 14px;
  color: #999999;
}
.index-goverment{
  width: 100%;
  height: 790px;
  padding-top: 55px;
  background: url('../../../static/images/goverment.png') no-repeat;
  background-size: cover;
  margin-bottom: 1px;
}
.index-goverment-title{
  width: 404px;
  height: 45px;
  background: url('../../../static/images/title-img4.png') no-repeat;
  margin: 0 auto;
  background-position:center;
}
.index-goverment-title>div{
  width: 128px;
  height: 45px;
  font-size: 32px;
  font-weight: 100;
  font-size: 32px;
  font-weight: 100;
  margin: 0 auto;
  color: white;
}
.index-goverment-title-english{
  width: 285px;
  margin: 0 auto 50px;
  font-size: 14px;
  text-align: center;
  color: #999999;
}
.index-goverment-chosecity{
  width: 1095px;
  height: 600px;
  /* border:1px solid red; */
  margin: 0 auto;
}
.chosecity1{
  height: 34px;
  line-height: 34px;
  margin-bottom: 21px;
}
.chosecity1>span{
  width: 72px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #999999;
  margin-right: 55px;
  cursor: pointer;
}
.chosecity2{
  margin-left: 15px;
  margin-bottom: 40px;
}
.chosecity2>span{
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin-right: 36px;
  margin-bottom: 20px;
  cursor: pointer;
}
.chosecity3{
  width: 1095px;
  height: 400px;
  /* border:1px solid red; */
  text-align: center;
  margin-bottom: 30px;
}
.chosecity4{
  color: white;
  font-size: 14px;
  float: right;
  cursor: pointer;
}
 .colorChange{
    width: 72px;
    height: 33px;
    border-radius: 20px;
    background-image: linear-gradient(to bottom, #fb9952, #EB5E00);
    font-size: 16px;
    font-weight: 500;
    color: white!important;
  }
  .medium>img{
    margin:124px 0 24px;
  }
  .medium>div{
    margin: 0 auto;
    width: 280px;
    height: 65px;
    border-bottom:1px solid white;
  }
  .medium>div>p:nth-of-type(1){
    margin: 0 auto;
    width: 280px;
    font-size: 22px;
    color: white;
    text-align: center;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
   .medium>div>p:nth-of-type(2){
     width: 280px;
    font-size: 14px;
    color: white;
    text-align: center;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   }
  .colorChange2{
    color: #EB5E00!important;
  }
.medium{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.medium2{
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #ffb765, #ff6924 99%);
  z-index: 1000;
}
.medium2>img{
  margin: 32px 0 10px;
}
.medium2>p:nth-of-type(1){
  width: 280px;
  margin: 0 auto 4px;
  text-align: center;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  color: white;
}
.medium2>p:nth-of-type(2){
  width: 280px;
  margin: 0 auto 4px;
  text-align: center;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: white;
}
.medium2>div{
  width: 322px;
  height: 195px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  color: white;
  overflow: hidden;
}
.medium2>div>div:nth-of-type(1){
  width: 322px;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 6px;
}
.medium2>div>div:nth-of-type(2){
  width: 322px;
  height: 145px;
  overflow : hidden;
  margin-bottom: 6px;
}
.medium2>div>div:nth-of-type(3){
  width: 322px;
}
.medium2>div>div:nth-of-type(2)>p{
  float: left!important;
}
.medium2>div>div:nth-of-type(3)>p{
  float: left!important;
}
.medium2>div>div:nth-of-type(3)>p:nth-of-type(2){
  width: 250px!important;
  white-space:nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}
  .index-new{
    width: 100%;
    height: 830px;
    padding-top: 55px;
     background-color: #f6f7f8;
  }
  .index-new-div1{
    width: 468px;
    height: 45px;
    background: url('../../../static/images/title-img5.png') no-repeat;
    margin: 0 auto;
    background-position:center;
  }
  .index-new-div1>div{
      width: 192px;
      height: 45px;
      font-size: 32px;
      font-weight: 100;
      text-align: center;
      color: #292929;
      margin: 0 auto;
  }
  .index-new-english{
      width: 192px;
      font-size: 14px;
      text-align: center;
      color: #999999;
      margin: 0 auto 50px;
  }
  .index-new-div2{
    width: 1148px;
    height: 617px;
    margin: 0 auto 33px;
    background-color: #ffffff;
  }
  .index-new-div2-top{
    width: 1148px;
    height: 300px;
    margin-bottom: 60px;
  }
  .index-new-div2-top>div{
    float: left;
    position: relative;
  }
  .news-top1{
    width: 746px;
    height: 300px;
    margin-right: 20px;
    cursor: pointer;
  }
  .news-top1-img{
    width: 746px;
    height: 300px;
  }
  .news-top1-div{
     position: absolute;
     bottom: 0;
     width: 746px;
     height: 168px;
     background-color: rgba(0, 0, 0, 0.6);
   }
   .news-top1-div>div:nth-of-type(1){
    width: 589px;
    height: 40px;
    margin: 24px auto 12px;
    font-size: 28px;
    color: #ffffff;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .news-top1-div>div:nth-of-type(2){
    width: 674px;
    height: 75px;
    font-size: 18px;
    font-weight: 300;
    color: #ffffff;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .news-top2{
    width: 382px;
    height: 300px;
    cursor: pointer;
  }
   .news-top2>img{
     width: 382px;
     height: 300px;
   }
   .news-top2>div{
     position: absolute;
     bottom: 0;
     width: 382px;
    height: 168px;
     background-color: rgba(0, 0, 0, 0.6);
   }
  .news-top2>div>div:nth-of-type(1){
    width: 168px;
    height: 40px;
    margin: 24px auto 12px;
    font-size: 28px;
    color: #ffffff;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .news-top2>div>div:nth-of-type(2){
    width: 310px;
    height: 75px;
    font-size: 18px;
    font-weight: 300;
    color: #ffffff;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .index-new-div2-detail{
    width: 100%;
    height: 220px;
  }
  .new-detail-list{
    width: 310px;
    height: 220px;
    float: left;
    cursor: pointer;
    margin: 0 36px;
  }
  .list1{
    width: 310px;
    height: 40px;
    border-bottom:2px solid #333333;
    margin-bottom: 16px;
  }
  .list1>div{
    float: left;
  }
  .list1>div:nth-of-type(1){
    width: 54px;
    height: 22px;
    border: solid 1px #333333;
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    color: #111111;
    margin-top: 2px;
    margin-right: 8px;
  }
  .list1>div:nth-of-type(2){
    width: 245px;
    height: 28px;
    font-size: 20px;
    color: #111111;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .list2{
    width: 310px;
    height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: 300;
    color: #666666;
  }
  .index-news-more{
    width: 1148px;
    margin: 0 auto;
  }
  .index-news-more>div{
    float: right;
    cursor: pointer;
  }
  .index-news-more>div>span{
    font-size: 14px;
    color: #999999;
    vertical-align: middle;
  }
  .index-news-more>div>img{
    vertical-align: middle;
  }
  .agency-active{
    width: 552px;
    height: 134px;
    margin: 80px auto 0;
  }
  .agency-active>div:nth-of-type(1){
    font-size: 48px;
    text-align: center;
    color: white;
    margin-bottom: 8px;
  }
  .agency-active>div:nth-of-type(2){
    width: 517px;
    height: 64px;
    line-height: 64px;
    font-size: 32px;
    margin: 0 auto;
    text-align: center;
    color: #fdd468;
    background: url('../../../static/images/priceback.png') no-repeat;
    background-size: cover;
  }
  .agency-active>div:nth-of-type(2)>span{
    font-size: 48px;
    font-weight: 600;
  }
  .index-cover{
    background: rgba(0,0,0, 0.4);
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .index-cover-tan{
    width: 730px;
    height: 376px;
    background-color: #ffffff;
    position: absolute;
    top:50%;
    left: 50%;
    z-index: 30;
    transform: translate(-50%,-50%);
  }
  .index-cover-tan>img{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  .index-cover-div{
    width: 476px;
    position: absolute;
    top: 58px;
    left: 127px;
  }
  .index-cover-div1{
    width: 160px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin: 0 auto 30px;
  }
  .index-cover-div2{
    height: 100px;
  }
  .index-cover-div2>div{
    float: left;
    margin-right: 30px;
  }
  .index-cover-div2>div:last-child{
    margin-right: 0;
  }
  .index-cover-div2>div>img{
    width: 120px;
    height: 120px;
  }
  .index-cover-div2-name>div:nth-of-type(1){
    font-size: 14px;
    margin: 14px 0 64px;
  }
  .index-cover-div2-name>div:nth-of-type(2){
    margin-bottom: 48px;
  }
  .index-cover-div2-opeat{
    width: 133px;
    height: 38px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #000000;
    line-height: 38px;
    color: #000000;
  }
  .index-cover-div2-link{
    padding-top: 7px;
  }
  .index-cover-div2-link>div:nth-of-type(1),.index-cover-div2-link>div:nth-of-type(2){
    width: 160px;
    height: 34px;
    line-height: 34px;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 17px;
  }
  .index-cover-div2-link>div>img{
    margin: 0 10px;
    vertical-align: middle;
  }
  .index-cover-div2-link>div>span{
    vertical-align: middle;
    font-size: 14px;  
  }
  .index-cover-div2-link>div:nth-of-type(2){
    margin: 47px 0 43px;
  }
  .op2{
    margin-left: 25px;
  }
  .index-cover-div2-name >>> .el-rate__icon {
  font-size: 14px;
  color: #F96009 !important;
}
.index-example{
  width: 800px;
    height: 600px;
    overflow: hidden;
    top: 175px;
    left: calc(50vw - 400px);
}
/deep/ .el-dialog{
   margin-top: 0!important;
    height: 600px!important;
}
.index-example-div{
  position: absolute;
  width: 800px;
  height: 600px;
   background: #ffffff;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    text-align:center;
    font-size:14px;
}
.index-example-span{
  display:inline-block;
  margin:21px 0 11px;
}
.index-example-middle{
  width: 800px;
height: 390px;
padding-top:40px;
background: #F9F9F9;
margin-bottom:45px;
}
.index-example-middle>span{
  display:inline-block;
  margin:0 0 60px;
}
.index-example-middle>div{
  width: 592px;
  margin:0 auto;
}
.index-example-middle>div>div{
  float: left;
  margin-right:74px;
}
.index-example-middle>div>div>img{
  margin-bottom:28px;
}
.index-example-middle>div>div:nth-of-type(3){
  margin-right:0;
}
.index-example-buttom{
  width: 245px;
    margin: 0 auto;
}
.index-example-buttom>span{
  display: inline-block;
    width: 110px;
    height: 34px;
    cursor: pointer;
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    border-radius: 6px;
}
.index-example-buttom>span:nth-of-type(1){
    border: 1px solid rgba(0, 0, 0, 0.45);
    margin-right: 22px;
    color: rgba(0, 0, 0, 0.45);
}
</style>
<style>
  .el-carousel__item div {
    text-align: center;
  }

  .el-carousel__item{
    background: url('../../../static/images/lunbo2.png') no-repeat;
    background-size: cover;
  }

  .el-carousel>ul>li>button{
    display: none;
  }
  .v-modal{
    z-index: 9!important;
}
</style>
